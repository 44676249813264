import { ResponsivePie } from "@nivo/pie";
import { ColorService } from "../services/colors";
import { Data } from "./PieAndBar";

export const Pie: React.FC<{data: Data[], title: string}> = ({data, title}: {data: Data[], title: string}) => {
  const Title = ({ width, height }: {width: number, height: number}) => {
    // You can console.log(data) to see what other properties are available

    const style = {fontWeight: 'bold'}

    return (
        <text 
            x={width / 2}
            y={-10}
            style={style}
        >
            {title}
        </text>
    )
} 
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
      colors={ColorService.getColor}
      key={"value"}
      enableArcLinkLabels={false}
      arcLinkLabel={d => `${d.id} (${d.formattedValue})`}
      innerRadius={0.6}
      padAngle={1}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', Title as any]}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        legends={[
          {
            anchor: "right",
            direction: "column",
            itemWidth: 180,
            itemHeight: 20,
            symbolSize: 12,
            symbolShape: "circle",
          },
        ]}
    />
  );
};
