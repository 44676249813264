import React from "react";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Dashboard} from '../components/Dashboard';

const App: React.FC = () => {
  return (
    <>
     <Dashboard />
    </>
  );
};


export default App;
