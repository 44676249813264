export const ColorService = {
  getColor(input: { index: number } | {data: {index: number}}) {
      const index = (input as any).index ?? (input as any).data.index;
    if (index % 6 === 0) return "#7e87d9";
    if (index % 6 === 1) return "#f4737d";
    if (index % 6 === 2) return "#dfcb6e";
    if (index % 6 === 3) return "#85b3d3";
    if (index % 6 === 4) return "#9fe793";
    if (index % 6 === 5) return "#ff7a05";
    return "pink";
  },
};
