import { Container, Icon, Text } from "@chakra-ui/react";

interface Props {
    icon: any;
    number: number;
    text: string;
}
export const SmallBox: React.FC<Props> = (props: Props) => {
    return (
    <Container
            centerContent
            border="1px"
            p="5px"
            m="5px"
            w="33%"
            h="200px"
            justifyContent="center"
          >
            <Icon fontSize="xx-large" as={props.icon} />
            <Text fontWeight="bold" fontSize="3xl" color="#4955c9">
              {props.number}
            </Text>
            <Text fontSize="md" color='#4A5568' textAlign="center">{props.text}</Text>
          </Container>
    );
};