import {
  Container,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Dashboard, DashboardData2024 } from "../services/data";
import { PieAndBar } from "./PieAndBar";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import { SmallBox } from "./SmallBox";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
export const DashboardDisplay: React.FC<{dashboard: Dashboard}> = ({dashboard}: {dashboard: Dashboard}) => {
  const data = dashboard.data as DashboardData2024;
  // Quirk: For a case like [white, unknown] it will be counted as "multiple" and not "unknown".
  // But for [male, unknown] it will be counted as both "multiple" and "unknown" because of the nature
  // of gender being broken down into multiple categories.
  let race = prepare([
    {
      value: data?.race?.americanIndian ?? 0,
      label: "American Indian, Alaska Native, or Indigenous",
    },
    {
      value: data?.race?.asian ?? 0,
      label: "Asian or Asian American",
    },
    {
      value: data?.race?.black ?? 0,
      label: "Black, African American, or African",
    },
    {
      value: data?.race?.middleEastern ?? 0,
      label: "Middle Eastern or North African",
    },
    {
      value: data?.race?.pacificIslander ?? 0,
      label: "Native Hawaiian or Pacific Islander",
    },
    {
      value: data?.race?.hispanic ?? 0,
      label: "Hispanic/Latina/e/o",
    },
    {
      value: data?.race?.white ?? 0,
      label: "White",
    },
    {
      value: data?.race?.indianAndHispanic ?? 0,
      label: "American Indian, Alaska Native, or Indigenous & Hispanic/Latina/e/o",
    },
    {
      value: data?.race?.asianAndHispanic ?? 0,
      label: "Asian or Asian American & Hispanic/Latina/e/o",
    },
    {
      value: data?.race?.blackAndHispanic ?? 0,
      label: "Black, African American, or African & Hispanic/Latina/e/o",
    },
    {
      value: data?.race?.middleEasternAndHispanic ?? 0,
      label: "Middle Eastern or North African & Hispanic/Latina/e/o",
    },
    {
      value: data?.race?.pacificIslanderAndHispanic ?? 0,
      label: "Native Hawaiian or Pacific Islander  & Hispanic/Latina/e/o",
    },
    {
      value: data?.race?.whiteAndHispanic ?? 0,
      label: "White & Hispanic/Latina/e/o",
    },
    {
      value: data?.race?.multiple ?? 0,
      label: "Multiple Races",
    },
    {
      value: data?.race?.unknown ?? 0,
      label: "Unknown",
    },
  ]);
  const gender = prepare([
    {
      value: data?.gender?.female ?? 0,
      label: "Woman (Girl if child)",
    },
    {
      value: data?.gender?.male ?? 0,
      label: "Man (Boy if child)",
    },
    {
      value: data?.gender?.nonBinary ?? 0,
      label: "Non-Binary",
    },
    {
      value: data?.gender?.questioning ?? 0,
      label: "Questioning",
    },
    {
      value: data?.gender?.transgender ?? 0,
      label: "Transgender",
    },
    {
      value: data?.gender?.culturallySpecificIdentity ?? 0,
      label: "Culturally Specific Identity",
    },
    {
      value: data?.gender?.multiple ?? 0,
      label: "Multiple",
    },
    {
      value: data?.gender?.differentIdentity ?? 0,
      label: "Other",
    },
    {
      value: data?.gender?.unknown ?? 0,
      label: "Unknown",
    },
  ]);
  const households = prepare([
    {
      value: data?.households?.onlyAdults ?? 0,
      label: "Only Adults",
    },
    {
      value: data?.households?.onlyChildren ?? 0,
      label: "Only Children",
    },
    {
      value: data?.households?.both ?? 0,
      label: "Adults and Children",
    },
    {
      value: data?.members?.unknownAgeHead ?? 0,
      label: "Unknown",
    },
  ]);

  return (
    <Container maxW="100%" centerContent p="20px">
      <Stack backgroundColor="#7e87d9" w="100%" p="5">
        <Heading size="lg" as="h2" color="white" textAlign="center">
          {dashboard?.settings.title}
        </Heading>
        <Heading size="lg" as="h3" color="white" textAlign="center">
          {dashboard?.settings.description}
        </Heading>
      </Stack>
      <Container maxW="80%">
        <HStack width="100%" p="10px">
          <Container
            centerContent
            border="1px"
            p="5px"
            m="5px"
            w="50%"
            h="200px"
            justifyContent="center"
          >
            <Text fontWeight="bold" fontSize="5xl">
              {data?.members?.individuals ?? 0}
            </Text>
            <Text fontSize="md" color="#4A5568" textAlign="center">
              people were recorded as homeless
            </Text>
          </Container>
          <Container
            centerContent
            border="1px"
            p="5px"
            m="5px"
            w="50%"
            h="200px"
            justifyContent="center"
          >
            <Text fontWeight="bold" fontSize="5xl">
              {data?.members?.chronic ?? 0}
            </Text>
            <Text fontSize="md" color="#4A5568" textAlign="center">
              people were recorded as chronically homeless
            </Text>
          </Container>
        </HStack>
      </Container>
      <Container maxW="80%">
        <HStack width="100%" p="10px">
          <SmallBox
            text="Children"
            number={data?.members?.children ?? 0}
            icon={ChildCareIcon}
          />
          <SmallBox
            text="Veterans"
            number={data?.members?.veterans ?? 0}
            icon={MilitaryTechIcon}
          />
          <SmallBox
            text="With Disabilities"
            number={data?.members?.disabilities ?? 0}
            icon={AccessibleForwardIcon}
          />
        </HStack>
      </Container>

      <PieAndBar data={households} title="Households" />
      <PieAndBar data={race} title="Race" />
      <PieAndBar data={gender} title="Gender" />
    </Container>
  );
};

function prepare(data: { value: number; label: string }[]) {
  return data?.map((r, i) => {
    return {
      index: i,
      id: r.label,
      ...r,
    };
  });
}
