export interface DashboardData2023 {
  members: {
    individuals: number;
    adults: number;
    youth: number;
    children: number;
    veterans: number;
    disabilities: number;
    chronic: number;
    dv: number;
    unknownAgeHead: number;
    unknownAge: number;
  };
  households: {
    onlyAdults: number;
    both: number;
    onlyChildren: number;
    total: number;
  };
  race: {
    americanIndian: number;
    asian: number;
    black: number;
    pacificIslander: number;
    white: number;
    multiple: number;
    unknown: number;
    total: number;
  };
  gender: {
    female: number;
    male: number;
    nonBinary: number;
    questioning: number;
    transgender: number;
    multiple: number;
    unknown: number;
    total: number;
  };
  ethnicity: {
    nonHispanic: number;
    hispanic: number;
    unknown: number;
    total: number;
  };
}

export interface DashboardData2024 {
  members: {
    individuals: number;
    adults: number;
    youth: number;
    children: number;
    veterans: number;
    disabilities: number;
    chronic: number;
    dv: number;
    unknownAgeHead: number;
    unknownAge: number;
  };
  households: {
    onlyAdults: number;
    both: number;
    onlyChildren: number;
    total: number;
  };
  race: {
    americanIndian: number;
    asian: number;
    black: number;
    pacificIslander: number;
    white: number;
    hispanic: number;
    middleEastern: number;
    multiple: number;
    whiteAndHispanic: number;
    blackAndHispanic: number;
    asianAndHispanic: number;
    pacificIslanderAndHispanic: number;
    indianAndHispanic: number;
    middleEasternAndHispanic: number;
    unknown: number;
    total: number;
  };
  gender: {
    female: number;
    male: number;
    nonBinary: number;
    questioning: number;
    culturallySpecificIdentity: number;
    transgender: number;
    differentIdentity: number;
    multiple: number;
    unknown: number;
    total: number;
  };
}

export type DashboardData = DashboardData2023 | DashboardData2024;

export type DashboardAccess = "anyone" | "noone";

export interface DashboardSettings {
  title: string;
  description: string;
  access: DashboardAccess;
}

export interface Dashboard {
  settings: DashboardSettings;
  data: DashboardData;
}

export type FakeId = "foo";

export const DataService = {
  Fake(id: FakeId): Dashboard | undefined {
    if (id === "foo") {
      return {
        settings: {
          title: "PIT Count 2022",
          description: "Example",
          access: "anyone",
        },
        data: {
          members: {
            individuals: 8 + 315,
            adults: 3 + 308,
            youth: 2 + 4,
            children: 3 + 3,
            veterans: 40,
            disabilities: 90,
            chronic: 75,
            dv: 7,
            unknownAgeHead: 0,
            unknownAge: 0,
          },
          households: {
            onlyAdults: 318,
            both: 4,
            onlyChildren: 1,
            total: 323,
          },
          race: {
            americanIndian: 6,
            asian: 2 + 2,
            black: 107 + 8,
            pacificIslander: 3,
            white: 8 + 180 + 5,
            multiple: 2,
            unknown: 0,
            total: 323,
          },
          gender: {
            female: 4 + 99 + 2,
            male: 4 + 209,
            nonBinary: 0,
            questioning: 1,
            transgender: 3,
            multiple: 1,
            unknown: 0,
            total: 323,
          },
          ethnicity: {
            nonHispanic: 8 + 223,
            hispanic: 81 + 11,
            unknown: 0,
            total: 323,
          },
        },
      };
    }
  },
};
