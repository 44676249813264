import { DataService, Dashboard as DB } from "../services/data";
import { useParams } from "react-router-dom";
import { getFirestore, doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { DashboardDisplay } from "./DashboardDisplay";
import { FirebaseError } from "firebase/app";
import { Message } from "./Message";
export const Dashboard: React.FC = () => {
  let params = useParams();
  let dashboard;

  const [value, loading, error] = useDocument(
    doc(getFirestore(), `dashboards/${params.code}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  console.log(value, loading, error);
  console.log(error);

  if (!params.code) {
    dashboard = DataService.Fake("foo");
  } else {

  }
  if (!!dashboard)
  return (
   <DashboardDisplay dashboard={dashboard} />
  );

  if (!loading && !error && !!value && !!value.data()) {
    const dashboard = value.data() as DB;
    return (
      <DashboardDisplay dashboard={dashboard} />
    )
  }

  const message = getMessage({loading, error});
  return <Message message={message} />
};

const getMessage = ({loading, error}: {loading: boolean, error: FirebaseError | undefined}): string => {
  if (!loading && (typeof error == 'undefined')) return 'Something went wrong';
  if (loading) return 'loading...';
  const err = error as FirebaseError;
  if (err.code === 'permission-denied') return "Either this dashboard doesn't exist or you don't have permission to view it. Is it public?";
  return err.message;
}
