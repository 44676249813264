import { Heading, Stack } from "@chakra-ui/react";
import { Pie } from "./Pie";

export interface Data {
  index: number;
  value: number;
  label: string;
}
export const PieAndBar: React.FC<{ data: Data[], title: string }> = ({
  data,
  title,
}: {
  data: Data[];
  title: string;
}) => {
  const inner = allZero(data) ? <Heading as="h4" size="lg">No data for {title} yet</Heading> : <Pie data={data} title={title} />;
  return (
    <Stack h="500px" width="80%" p="10px">
      {inner}
    </Stack>
  );
};

function allZero(data: Data[]) {
  return data.filter(d => d.value !== 0).length === 0;
}